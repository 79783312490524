<template>
  <div>
    <ActionButton
      v-if="permission.new || permission.newForClient"
      class="mb-15"
      icon="mdi-plus"
      @click="newHandler('NewTarget')"
    >
      <span class="d-none d-sm-block">{{ $t('button.newTarget') }}</span>
    </ActionButton>
    <v-slide-y-transition hide-on-leave>
      <CustomTableFilter
        v-if="isShowFilter"
        :params="params"
        :selectedFilters="selectedFilters"
        @add="addParamHandler"
        @clear="clearHandler"
        @delete="deleteParamHandler"
      />
    </v-slide-y-transition>
    <TargetsTable
      ref="targetsTable"
      :selectedFilters="selectedFilters"
      @action="actionHandler"
      @filter="addFilterHandler"
    />
    <CustomDialog v-model="isActionDialog" minHeight="250">
      <div class="text-center mt-15">
        <h3>{{ $t('dialog.deleteTitle') }}</h3>
        <ActionButton class="my-15" :loading="actionLoading" @click="deleteHandler">
          {{ $t('button.confirm') }}
        </ActionButton>
      </div>
    </CustomDialog>
  </div>
</template>
<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import TargetsTable from '@/components/table/universal/TargetsTable'
import substringLength from '@/helpers/substringLength'
import {newHandler} from '@/helpers/handler/newHandler'

export default {
  name: 'Targets',
  components: {TargetsTable},
  data: () => ({
    actionItem: null,
    isShowFilter: false,
    params: {},
    selectedFilters: [],
    isActionDialog: false,
  }),
  computed: {
    ...mapState({
      actionLoading: (state) => state.target.actionLoading,
    }),
    ...mapGetters({
      isAdmin: 'auth/isAdmin',
      isManager: 'auth/isManager',
      permission: 'target/permission',
    }),
  },
  methods: {
    ...mapActions({
      deleteTarget: 'target/DELETE_TARGET',
    }),
    substringLength,
    newHandler,
    async loadData() {
      let params
      this.selectedFilters.forEach((a) => {
        params = Object.assign({[a.name]: a.value}, params)
      })
      await this.$refs.targetsTable.loadData(params, true)
    },
    actionHandler({item, type}) {
      let name = ''
      this.actionItem = item
      switch (type) {
        case 'details':
          switch (true) {
            case this.isAdmin:
              name = 'AdminTargetDetail'
              break
            case this.isManager:
              name = 'ManagerTargetDetail'
              break
            default:
              name = 'AdvertiserTargetDetail'
          }
          this.$router.push({name: name, params: {id: item.id}})
          break
        case 'delete':
          this.showActionDialog()
          break
        default:
          break
      }
    },
    addFilterHandler(item) {
      this.isShowFilter = true
      this.params = {
        name: item.value,
        value: '',
        placeholder: item.placeholder ?? item.value,
        type: item.filterType,
      }
    },
    addParamHandler() {
      const isDuplicate = this.selectedFilters.some((a) => a.name === this.params.name)
      if (!isDuplicate) {
        this.selectedFilters = [...this.selectedFilters, {...this.params}]
      } else {
        let index = this.selectedFilters.findIndex((a) => a.name === this.params.name)
        Object.assign(this.selectedFilters[index], this.params)
      }
      this.loadData()
    },
    deleteParamHandler(item) {
      this.selectedFilters = this.selectedFilters.filter((a) => a.name !== item.name)
      if (this.selectedFilters.length === 0) this.isShowFilter = false
      this.loadData()
    },
    clearHandler() {
      this.isShowFilter = false
      this.params = {}
      this.selectedFilters = []
      this.loadData()
    },
    showActionDialog() {
      this.isActionDialog = true
    },
    async deleteHandler() {
      const {id} = this.actionItem
      await this.deleteTarget(id)
      this.isActionDialog = false
      await this.loadData()
    },
  },
}
</script>
